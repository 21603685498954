import { defineStore } from 'pinia'
import { ref } from 'vue'
import { IAuth } from '@/components/pages/auth/type'

export const useAuthStore = defineStore('auth', () => {
	const token = ref(localStorage.getItem('token'))
	const refreshToken = ref(localStorage.getItem('refreshToken'))
	const setAuth = (authResponse: IAuth) => {
		token.value = authResponse.accessToken
		refreshToken.value = authResponse.refreshToken
		localStorage.setItem('token', token.value)
		localStorage.setItem('refreshToken', refreshToken.value)
	}
	const $reset = () => {
		token.value = null
		refreshToken.value = null
	}
	return { token, refreshToken, setAuth, $reset }
})
